import { all, takeEvery, call, put } from "redux-saga/effects";
import actions from "./actions";
import loggingActions from "../logging/actions";
import { fetchResource } from "../../services/fetchResource";
import { triggerJob } from "../../services/triggerJob";

function* fetchJobPage(action) {
  const { payload } = action;
  const { pageDefinition, pageKey } = payload;
  const { resourceName } = pageDefinition;
  const result = yield call(fetchResource, payload);

  const { status, data } = result;

  if (status === 200) {
    // yield put(loggingActions.addLogInfo("Fetched Jobs", JSON.stringify(result))); // todo: figure out why I cannot test that I am setting the log body
    yield put(loggingActions.addLogInfo("Fetched Jobs"));
    yield put(actions.setPageContents({ resourceName, key: pageKey, pageContents: data }))
  } else {
    // yield put(loggingActions.addLogError("Failed to Fetched Jobs", JSON.stringify(result))); // todo: figure out why I cannot test that I am setting the log body
    yield put(loggingActions.addLogError("Failed to Fetched Jobs"));
  }
}

function* triggerJobList(action) {
  const { payload: jobListToExecute } = action;
  
  for(const jobName of jobListToExecute) {
    yield call(triggerJob, jobName);
  }

  const triggeredItem = jobListToExecute.length === 1 ? jobListToExecute[0] : "multiple jobs";
  yield put(loggingActions.addLogSuccess(`Triggered ${triggeredItem}`));
}

export const __test__ = {
  fetchJobPage,
  triggerJobList
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_PAGE, fetchJobPage),
    takeEvery(actions.TRIGGER_JOB_LIST, triggerJobList),
  ]);
}
