import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

function AppProvider({children}) {
  const themeWithTestProps = {
    ...theme,
    props: { MuiWithWidth: { initialWidth: "lg" } },
  };
  
  return (
    <ThemeProvider theme={themeWithTestProps}>
      {children}
    </ThemeProvider>
  );
}

export default AppProvider;