import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createRootReducer } from "./root-reducer";
import rootSaga from "./root-saga";

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];


  const store = createStore(
    createRootReducer(),
    bindMiddleware(middleware),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}

export default initStore();
