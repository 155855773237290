export const pageKeyGenerator = (searchParams = {}) =>
  Object.entries(searchParams)
    .filter(([_, value]) => typeof value !== "undefined")
    .sort(([keyOne], [keyTwo]) => sortByKey(keyOne, keyTwo))
    .reduce((accumulator, [key, value]) => {
      const joinString = accumulator !== "" ? "&" : "";
      return accumulator + joinString + key + "=" + encodeURIComponent(value);
    }, "");

const sortByKey = (firstEl, secondEl) => {
  const uppercaseFirstEl = firstEl.toUpperCase();
  const uppercaseSecondEl = secondEl.toUpperCase();
  
  if (uppercaseFirstEl < uppercaseSecondEl) {
    return -1;
  }
  if (uppercaseFirstEl > uppercaseSecondEl) {
    return 1;
  }

  return 0;
};