import actions from "./actions";
import { createNavigableRoute } from "../../utils/createNavigableRoute";

const initState = {pendingRoute: null};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case actions.SET_PENDING_ROUTE: {
      return {
        ...state,
        pendingRoute: createNavigableRoute(payload),
      }
    }
    case actions.CLEAR_PENDING_ROUTE: {
      return {
        ...state,
        pendingRoute: null,
      }
    }
    default: {
      return state;
    }
  }
}