import actions from "./actions";

const initState = {
  logs: [],
  showLogWindow: false,
}

const expireLogWhenMatching = (expiringLogId) => (log) => {
  const { logId } = log;

  if (logId === expiringLogId) {
    return {...log, activeMessage: false}
  }
  return log;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.ADD_LOG_INSERT: {
      const { level, message, logId } = payload;
      return {
        ...state,
        logs: [
          ...state.logs,
          { level, message, logId, activeMessage: true }
        ]
      }
    }
    case actions.ADD_LOG_EXPIRE: {
      const expiringLogId = payload;
      return {
        ...state,
        logs: state.logs.map(expireLogWhenMatching(expiringLogId))
      }
    }
    case actions.TOGGLE_LOG_WINDOW: {
      return {
        ...state,
        showLogWindow: !state.showLogWindow
      };
    }
    default:
      return state;
  }
}
