import { useContext } from "../../utils/reactAdapter";
import { AuthContext } from "../../providers/authProvider";
import { useOnMount } from "../../hooks";

export const useAuthMountCallback = (callback) => {
  const authContext = useContext(AuthContext);
  
  useOnMount(() => {
    callback(authContext);
  });
};