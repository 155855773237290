import axios from "axios";
import { API_ROUTE } from "../constants/routes";

const dashboardApi = axios.create({
  baseURL: API_ROUTE.BASE_URL,
  timeout: 60000, // 1 min
  headers: {
    "Content-type": "application/json",
    accept: "application/json",
  }
});

export default dashboardApi;
