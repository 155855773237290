import React from "react";
import { Tooltip } from "@material-ui/core";

export const AuthDisabledTooltip = ({
  disabled,
  message = "Talk to your manager to get access",
  fallback = "", 
  children}) => (
  <Tooltip title={disabled ? message : fallback}>
    <div>
      {children}
    </div>
  </Tooltip>
);