import React, { useState } from "react";
import clsx from "clsx";
import { Box, AppBar, Toolbar, Typography, IconButton, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Menu as MenuIcon } from "@material-ui/icons";
import useStyles from "./Dashboard.styles";
import { DashboardDrawer } from "./DashboardDrawer";
import constants from "../../constants/constants";
import { DashboardRouter } from "./DashboardRouter";
import { LoggerBadge } from "./LoggerBadge";
import { PRIVATE_ROUTE } from "../../constants/routes";
import { useAppRouter } from "../../hooks";

const Copyright = () => (
    <Link style={{
        color: '#9d9d9d',
    }} to={PRIVATE_ROUTE.DASHBOARD}>
    © Fair Go Finance Dashboard v{constants.version}
  </Link>
);

const Dashboard = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useAppRouter();

  const [pageTitle, setPageTitle] = useState("");

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon/>
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {pageTitle}
          </Typography>
          <LoggerBadge />
        </Toolbar>
      </AppBar>
      <DashboardDrawer open={open} handleDrawerClose={handleDrawerClose} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <Container maxWidth="lg" className={classes.container}>
          <DashboardRouter setPageTitle={setPageTitle} />
                  <Box pt={4} style={{
                      display: 'flex',
                      justifyContent: 'center',
                  }}>
            <Copyright/>
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default Dashboard
