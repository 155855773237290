import React, { lazy, Suspense } from "react";
import { PRIVATE_ROUTE } from "../../constants/routes";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "../../components/utility";
import { useOnMount } from "../../hooks";
import { Skeleton } from "@material-ui/lab";

const routes = [
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    exact: true,
    component: lazy(() => import("../dashboard/HomePage")),
    meta: {
      pageTitle: "Dashboard"
    }
  },
  {
    path: PRIVATE_ROUTE.JOBS_RECURRING,
    exact: true,
    component: lazy(() => import("../JobStatusView/RecurringJob")),
    meta: {
      pageTitle: "Recurring Jobs"
    }
  },
  {
    path: PRIVATE_ROUTE.JOBS_PROCESSING,
    exact: true,
    component: lazy(() => import("../JobStatusView/ProcessingJob")),
    meta: {
      pageTitle: "Processing Jobs"
    }
  },
  {
    path: PRIVATE_ROUTE.JOBS_FAILED,
    exact: true,
    component: lazy(() => import("../JobStatusView/FailedJob")),
    meta: {
      pageTitle: "Failed Jobs"
    }
  },
  {
    path: PRIVATE_ROUTE.JOBS_SUCCEEDED,
    exact: true,
    component: lazy(() => import("../JobStatusView/SucceededJob")),
    meta: {
      pageTitle: "Succeeded Jobs"
    }
  },
  {
    path: PRIVATE_ROUTE.JOB_DETAIL,
    exact: true,
    component: lazy(() => import("../JobDetailView")),
    meta: {
      pageTitle: "Job Details"
    }
  },
  {
    path: PRIVATE_ROUTE.USER_DETAIL,
    exact: false,
    component: lazy(() => import("../UserView")),
    meta: {
      pageTitle: "User Details"
    }
  },
  {
    path: PRIVATE_ROUTE.APPLICATIONS_FAILED,
    exact: false,
    component: lazy(() => import("../FailedApplicationView")),
    meta: {
      pageTitle: "Failed Applications"
    }
  },
  {
    path: PRIVATE_ROUTE.STAFF_CLAIMS,
    exact: false,
    component: lazy(() => import("../StaffClaimsView")),
    meta: {
      pageTitle: "Staff Claims"
    }
  },
  {
    path: PRIVATE_ROUTE.CONFIGURATION_PRODUCTS,
    exact: false,
    component: lazy(() => import("../ProductConfigurationView")),
    meta: {
      pageTitle: "Product Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.CONFIGURATION_OFFER_ENGINE,
    exact: false,
    component: lazy(() => import("../OfferEngineConfigurationView")),
    meta: {
      pageTitle: "Offer Engine Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.CONFIGURATION_DECISION_CARD,
    exact: false,
    component: lazy(() => import("../DecisionCardConfigurationView")),
    meta: {
      pageTitle: "Decision Card Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.CONFIGURATION_INTEREST_RATES,
    exact: false,
    component: lazy(() => import("../InterestRateConfigurationView")),
    meta: {
      pageTitle: "Interest Rates Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.PARTNERS_ELIGIBILITY,
    exact: false,
    component: lazy(() => import("../EligibilityConfigurationView")),
    meta: {
      pageTitle: "Eligibility Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.PARTNERS_REFERRALS,
    exact: false,
    component: lazy(() => import("../ReferralConfigurationView")),
    meta: {
      pageTitle: "Partner Referrals Configuration"
    }
  },
  {
    path: PRIVATE_ROUTE.PARTNERS_LEAD_STATUS,
    exact: false,
    component: lazy(() => import("../LeadStatusConfigurationView")),
    meta: {
      pageTitle: "Partner Lead Status Configuration"
    }
  }
]

const RouteRenderer = ({ route, routeProps, setPageTitle }) => {
  const { meta } = route;
  const { pageTitle = "" } = meta || {};
  const { match } = routeProps;
  const { params } = match;

  useOnMount(() => {
    setPageTitle(pageTitle);
  });

  return (
    <route.component meta={meta} {...params} />
  );
};

export const DashboardRouter = ({ setPageTitle }) => (
  <Suspense fallback={<Skeleton variant="text" width="100%" height="100px" />}>
    <Switch>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} exact={route.exact} render={(routeProps) => (
          <RouteRenderer route={route} setPageTitle={setPageTitle} routeProps={routeProps} />
        )} />
      ))}
      <Route>
        <Redirect pathname={PRIVATE_ROUTE.DASHBOARD} withSearch />
      </Route>
    </Switch>
  </Suspense>
);