import { makeStyles } from "@material-ui/core/styles";
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    justifyContent: "space-between",
    backgroundColor: alpha(theme.palette.grey["500"], 0),
  },
  alertContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
  },
  alertContainerOpen: {
    overflowY: "auto"
  },
  rootOpen: {
    backgroundColor: alpha(theme.palette.grey["500"], 0.8),
    pointerEvents: "auto",
    [theme.breakpoints.up('sm')]: {
      backgroundColor: alpha(theme.palette.grey["500"], 0.2),
    }
  },
  showButton: {
    alignSelf: "flex-end",
    pointerEvents: "auto"
  },
  alert: {
    marginTop: theme.spacing(0.5)
  },
  logButtonGroup: {
    display: "none"
  },
  showLogButtonGroup: {
    display: "flex",
    "& > *": {
      flex: "1"
    }
  },
}));

export default useStyles;