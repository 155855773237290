import { createEmptyAction, createPayloadAction } from "../utility/actions";
import { addYears } from "date-fns";
import { getNow } from "../../constants/now";
import { pageKeyGenerator } from "../../utils/pageKeyGenerator";

const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
const START_USER_FETCHING = "START_USER_FETCHING";
const END_USER_FETCHING = "END_USER_FETCHING";
const CLEAR_SEARCH_RESULT = "CLEAR_SEARCH_RESULT";
const POST_USER_LOCK = "POST_USER_LOCK";
const POST_USER_LOCK_SUCCESS = "POST_USER_LOCK_SUCCESS";
const POST_USER_UNLOCK = "POST_USER_UNLOCK";
const POST_USER_UNLOCK_SUCCESS = "POST_USER_UNLOCK_SUCCESS";
const ADD_USER_START = "ADD_USER_START";
const ADD_USER_START_SUCCESS = "ADD_USER_START_SUCCESS";
const ADD_USER_CONFIRM = "ADD_USER_CONFIRM";
const ADD_USER_CONFIRM_SUCCESS = "ADD_USER_CONFIRM_SUCCESS";
const UPDATE_USER_EMAIL_START = "UPDATE_USER_EMAIL_START";
const UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS";
const GET_ELIGIBILITY_SEARCH_RESULT = "GET_ELIGIBILITY_SEARCH_RESULT";
const SET_ELIGIBILITY_SEARCH_RESULT = "SET_ELIGIBILITY_SEARCH_RESULT";
const START_ELIGIBILITY_SEARCH = "START_ELIGIBILITY_SEARCH";
const END_ELIGIBILITY_SEARCH = "END_ELIGIBILITY_SEARCH";
const CLEAR_ELIGIBILITY_SEARCH = "CLEAR_ELIGIBILITY_SEARCH";
const GET_FAILED_APPLICATIONS_RESULT = "GET_FAILED_APPLICATIONS_RESULT";
const SET_FAILED_APPLICATIONS_RESULT = "SET_FAILED_APPLICATIONS_RESULT";
const START_FAILED_APPLICATIONS_SEARCH = "START_FAILED_APPLICATIONS_SEARCH";
const END_FAILED_APPLICATIONS_SEARCH = "END_FAILED_APPLICATIONS_SEARCH";
const POST_RETRY_FAILED_APPLICATION = "POST_RETRY_FAILED_APPLICATION";
const POST_RETRY_FAILED_APPLICATION_SUCCESS = "POST_RETRY_FAILED_APPLICATION_SUCCESS";
const POST_ARCHIVE_FAILED_APPLICATION = "POST_ARCHIVE_FAILED_APPLICATION";
const POST_ARCHIVE_FAILED_APPLICATION_SUCCESS = "POST_ARCHIVE_FAILED_APPLICATION_SUCCESS";
const REMOVE_FAILED_TSS_APPLICATION = "REMOVE_FAILED_TSS_APPLICATION";
const FILTER_FAILED_APPLICATIONS = "FILTER_FAILED_APPLICATIONS";
const CLEAR_FILTER_FAILED_APPLICATIONS = "CLEAR_FILTER_FAILED_APPLICATIONS";
const GET_STAFF_USERS = "GET_STAFF_USERS";
const SET_STAFF_USERS = "SET_STAFF_USERS";
const START_STAFF_SEARCH = "START_STAFF_SEARCH";
const END_STAFF_SEARCH = "END_STAFF_SEARCH";
const GET_CLAIMS_LIST = "GET_CLAIMS_LIST";
const SET_CLAIMS_LIST = "SET_CLAIMS_LIST";
const START_CLAIMS_LIST_SEARCH = "START_CLAIMS_LIST_SEARCH";
const END_CLAIMS_LIST_SEARCH = "END_CLAIMS_LIST_SEARCH";
const START_ASSIGN_CLAIMS = "START_ASSIGN_CLAIMS";
const ASSIGN_CLAIMS_SUCCESS = "ASSIGN_CLAIMS_SUCCESS";
const START_GET_PRODUCT_CONFIGURATIONS = "START_GET_PRODUCT_CONFIGURATIONS";
const END_GET_PRODUCT_CONFIGURATIONS = "END_GET_PRODUCT_CONFIGURATIONS";
const GET_PRODUCT_CONFIGURATIONS = "GET_PRODUCT_CONFIGURATIONS";
const SET_PRODUCT_CONFIGURATIONS = "SET_PRODUCT_CONFIGURATIONS";
const UPDATE_PRODUCT_CONFIGURATIONS = "UPDATE_PRODUCT_CONFIGURATIONS";
const UPDATE_PRODUCT_CONFIGURATIONS_SUCCESS = "UPDATE_PRODUCT_CONFIGURATIONS_SUCCESS";
const UPDATE_PRODUCT_CONFIGURATIONS_FAILURE = "UPDATE_PRODUCT_CONFIGURATIONS_FAILURE";

const getPermanentLockoutEndDate = () => (addYears(getNow(), 1000)).toISOString();

export const getLockingObject = ({ userName, isLockout }) => ({
  userName,
  optimisticLockoutEnd: isLockout ? getPermanentLockoutEndDate() : undefined
});

const createKeyBasedQuery = (type, key, query) => createPayloadAction(type)({ key, query });

const unlockUser = ({ key, userName }) =>
  createKeyBasedQuery(POST_USER_UNLOCK, key, getLockingObject({ userName, isLockout: false }));

const lockUser = ({ key, userName }) =>
  createKeyBasedQuery(POST_USER_LOCK, key, getLockingObject({ userName, isLockout: true }));

const eligibilitySearch = (clientId) =>
  createKeyBasedQuery(GET_ELIGIBILITY_SEARCH_RESULT, "", clientId);

const fetchFailedTssApplications = () =>
  createKeyBasedQuery(GET_FAILED_APPLICATIONS_RESULT, "", "");

const fetchStaffUsersList = () =>
  createKeyBasedQuery(GET_STAFF_USERS, "", "");

const fetchClaimsList = () =>
  createKeyBasedQuery(GET_CLAIMS_LIST, "", "");

const fetchProductConfigurations = () =>
  createKeyBasedQuery(GET_PRODUCT_CONFIGURATIONS, "", "");

const retryFailedTssApplication = (id) =>
  createKeyBasedQuery(POST_RETRY_FAILED_APPLICATION, "", id);

const archiveFailedTssApplication = (id) =>
  createKeyBasedQuery(POST_ARCHIVE_FAILED_APPLICATION, "", id);

const createGeneratedKeyBasedQuery = (type) => (params) => {
  const key = pageKeyGenerator(params);
  return createKeyBasedQuery(type, key, params);
};

const actions = {
  GET_SEARCH_RESULT,
  SET_SEARCH_RESULT,
  START_USER_FETCHING,
  END_USER_FETCHING,
  CLEAR_SEARCH_RESULT,
  START_STAFF_SEARCH,
  END_STAFF_SEARCH,
  POST_USER_LOCK,
  POST_USER_LOCK_SUCCESS,
  POST_USER_UNLOCK,
  POST_USER_UNLOCK_SUCCESS,
  ADD_USER_START,
  ADD_USER_START_SUCCESS,
  ADD_USER_CONFIRM,
  ADD_USER_CONFIRM_SUCCESS,
  UPDATE_USER_EMAIL_START,
  UPDATE_USER_EMAIL_SUCCESS,
  GET_ELIGIBILITY_SEARCH_RESULT,
  SET_ELIGIBILITY_SEARCH_RESULT,
  START_ELIGIBILITY_SEARCH,
  END_ELIGIBILITY_SEARCH,
  CLEAR_ELIGIBILITY_SEARCH,
  GET_FAILED_APPLICATIONS_RESULT,
  SET_FAILED_APPLICATIONS_RESULT,
  START_FAILED_APPLICATIONS_SEARCH,
  END_FAILED_APPLICATIONS_SEARCH,
  POST_RETRY_FAILED_APPLICATION,
  POST_RETRY_FAILED_APPLICATION_SUCCESS,
  POST_ARCHIVE_FAILED_APPLICATION,
  POST_ARCHIVE_FAILED_APPLICATION_SUCCESS,
  REMOVE_FAILED_TSS_APPLICATION,
  FILTER_FAILED_APPLICATIONS,
  CLEAR_FILTER_FAILED_APPLICATIONS,
  GET_STAFF_USERS,
  SET_STAFF_USERS,
  SET_CLAIMS_LIST,
  GET_CLAIMS_LIST,
  START_GET_PRODUCT_CONFIGURATIONS,
  END_GET_PRODUCT_CONFIGURATIONS,
  GET_PRODUCT_CONFIGURATIONS,
  SET_PRODUCT_CONFIGURATIONS,
  UPDATE_PRODUCT_CONFIGURATIONS,
  UPDATE_PRODUCT_CONFIGURATIONS_SUCCESS,
  UPDATE_PRODUCT_CONFIGURATIONS_FAILURE,
  START_CLAIMS_LIST_SEARCH,
  END_CLAIMS_LIST_SEARCH,
  START_ASSIGN_CLAIMS,
  ASSIGN_CLAIMS_SUCCESS,
  clearFilterSearchResults: createEmptyAction(CLEAR_FILTER_FAILED_APPLICATIONS),
  filterSearchResult: createPayloadAction(FILTER_FAILED_APPLICATIONS),
  getSearchResult: createGeneratedKeyBasedQuery(GET_SEARCH_RESULT),
  setSearchResult: createPayloadAction(SET_SEARCH_RESULT),
  clearSearch: createEmptyAction(CLEAR_SEARCH_RESULT),
  startFetching: createEmptyAction(START_USER_FETCHING),
  endFetching: createEmptyAction(END_USER_FETCHING),
  claimListFetchEnd: createEmptyAction(END_CLAIMS_LIST_SEARCH),
  claimListFetchStart: createEmptyAction(START_CLAIMS_LIST_SEARCH),
  staffSearchEnd: createEmptyAction(END_STAFF_SEARCH),
  staffSearchStart: createEmptyAction(START_STAFF_SEARCH),
  lockUser,
  fetchStaffUsersList,
  fetchClaimsList,
  fetchProductConfigurations,
  onLockUserSuccess: createPayloadAction(POST_USER_LOCK_SUCCESS),
  assignClaimsStart: createGeneratedKeyBasedQuery(START_ASSIGN_CLAIMS),
  assignClaimsSuccess: createEmptyAction(ASSIGN_CLAIMS_SUCCESS),
  unlockUser,
  onUnLockUserSuccess: createPayloadAction(POST_USER_UNLOCK_SUCCESS),
  startUserAdd: createGeneratedKeyBasedQuery(ADD_USER_START),
  onStartUserAddSuccess: createPayloadAction(ADD_USER_START_SUCCESS),
  addUserConfirm: createPayloadAction(ADD_USER_CONFIRM),
  onAddUserConfirmSuccess: createPayloadAction(ADD_USER_CONFIRM_SUCCESS),
  startEmailUpdate: createGeneratedKeyBasedQuery(UPDATE_USER_EMAIL_START),
  onEmailUpdateSuccess: createPayloadAction(UPDATE_USER_EMAIL_SUCCESS),
  getEligibilitySearchResult: createGeneratedKeyBasedQuery(GET_SEARCH_RESULT),
  setEligibilitySearchResult: createPayloadAction(SET_ELIGIBILITY_SEARCH_RESULT),
  clearEligibilitySearch: createEmptyAction(CLEAR_ELIGIBILITY_SEARCH),
  startEligibilitySearch: createEmptyAction(START_ELIGIBILITY_SEARCH),
  endEligibilitySearch: createEmptyAction(END_ELIGIBILITY_SEARCH),
  eligibilitySearch,
  getStaffUsers: createGeneratedKeyBasedQuery(GET_STAFF_USERS),
  getClaimsList: createGeneratedKeyBasedQuery(GET_CLAIMS_LIST),
  startGetProductConfigurations: createEmptyAction(START_GET_PRODUCT_CONFIGURATIONS),
  endGetProductConfigurations: createEmptyAction(END_GET_PRODUCT_CONFIGURATIONS),
  getProductConfigurations: createGeneratedKeyBasedQuery(GET_PRODUCT_CONFIGURATIONS),
  setProductConfigurations: createPayloadAction(SET_PRODUCT_CONFIGURATIONS),
  updateProductConfigurations: createPayloadAction(UPDATE_PRODUCT_CONFIGURATIONS),
  updateProductConfigurationsSuccess: createEmptyAction(UPDATE_PRODUCT_CONFIGURATIONS_SUCCESS),
  updateProductConfigurationsFailure: createEmptyAction(UPDATE_PRODUCT_CONFIGURATIONS_FAILURE),
  getFailedApplicationsResult: createGeneratedKeyBasedQuery(GET_FAILED_APPLICATIONS_RESULT),
  setFailedApplicationsResult: createPayloadAction(SET_FAILED_APPLICATIONS_RESULT),
  setStaffUsers: createPayloadAction(SET_STAFF_USERS),
  setClaimsList: createPayloadAction(SET_CLAIMS_LIST),
  startFailedApplicationsSearch: createEmptyAction(START_FAILED_APPLICATIONS_SEARCH),
  endFailedApplicationsSearch: createEmptyAction(END_FAILED_APPLICATIONS_SEARCH),
  fetchFailedTssApplications,
  retryFailedTssApplication,
  retryFailedTssApplicationSuccess: createEmptyAction(POST_RETRY_FAILED_APPLICATION_SUCCESS),
  archiveFailedTssApplication,
  archiveFailedTssApplicationSuccess: createEmptyAction(POST_ARCHIVE_FAILED_APPLICATION_SUCCESS),
  removeFailedTssApplication: createPayloadAction(REMOVE_FAILED_TSS_APPLICATION)
};

export default actions;