import { getLocalStorageItem, setLocalStorageItem } from "../utils/localStorageAdapter";
import { getNow } from "../constants/now";
import { setLocation } from "../utils/windowAdapter";

const signingHasAttemptedRedirectKey = "signingHasAttemptedRedirect";

const redirectHome = () => {
  setLocalStorageItem(signingHasAttemptedRedirectKey, getNow().toString());
  setLocation("/");
};

const oneMinuteTicks = 60 * 1000;
const getTicksAtOneMinuteAgo = () => getNow().getTime() - oneMinuteTicks;

// look for a key in localStorage and parse it's date.
// if the date is from over 1 min ago OR there is no key
// redirect to home and set the key so that we don't

// get stuck in a redirect loop
export const safeRedirectHome = () => {
  const signInRedirectAttempted = getLocalStorageItem(signingHasAttemptedRedirectKey);
  if (signInRedirectAttempted !== null) {
    const signInRedirectAttemptedTicks = Date.parse(signInRedirectAttempted);

    const minAgeBeforeCanRedirectTicks = getTicksAtOneMinuteAgo();
    if (signInRedirectAttemptedTicks <= minAgeBeforeCanRedirectTicks) { // if the sign in happened over 1 min ago
      redirectHome();
    }
  } else {
    redirectHome();
  }
};