const makeSafe = str => str ?? "";

export const titleCase = str => {
  const safeStr = makeSafe(str);
  const [firstCharacter, ...rest] = safeStr;
  return makeSafe(firstCharacter).toUpperCase() + rest.join("").toLowerCase();
}

export const camelCase = str => {
  const safeStr = makeSafe(str);
  const [firstCharacter, ...rest] = safeStr;
  return [makeSafe(firstCharacter).toLowerCase(), ...rest].join("");
};

export const camelCaseToSentence = (type) => {
  const safeType = type || "";
  const camelCaseChunkMatcher = new RegExp(/(^[^A-Z]+)|([A-Z]+[a-z]+)/g);

  return (safeType.match(camelCaseChunkMatcher) || []).join(" ");
};