import store from "./store";
import actions from "../redux/logging/actions";

const boot = _payload =>
  new Promise(() => {
    store.dispatch(actions.addLogDebug("booting application"));
    // store.dispatch(actions.addLogDebug("addLogDebug"));
    // store.dispatch(actions.addLogInfo("addLogInfo"));
    // store.dispatch(actions.addLogWarning("addLogWarning"));
    // store.dispatch(actions.addLogSuccess("addLogSuccess"));
    // store.dispatch(actions.addLogError("addLogError"));
  });

export default boot;
