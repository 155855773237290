import { createPayloadAction } from "../utility/actions";

const GET_JOB_DETAIL = "GET_JOB_DETAIL";
const SET_JOB_DETAIL = "SET_JOB_DETAIL";

const actions = {
  GET_JOB_DETAIL,
  SET_JOB_DETAIL,
  getJobDetail: createPayloadAction(GET_JOB_DETAIL),
  setJobDetail: createPayloadAction(SET_JOB_DETAIL),
}

export default actions;