import React from "react";
import { useContext } from "../../utils/reactAdapter";
import { Route } from "react-router-dom";
import { AuthContext } from "../../providers/authProvider";
import { RedirectToSignin } from "./redirectToSignin";

export const PrivateRoute = ({ path, exact, component, children }) => {
    const authContext = useContext(AuthContext);
    
    return authContext.isAuthenticated() ? 
      <Route path={path} exact={exact} component={component}>{children}</Route> : 
      <Route path={path} exact={exact}><RedirectToSignin /></Route>;
};
