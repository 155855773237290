import { all, call, put, takeEvery } from "redux-saga/effects";
import { isString } from "lodash"
import {
  addUserConfirmRequest,
  addUserStartRequest,
  changeUserNameRequest,
  fetchSearchResults,
  lockUser,
  unLockUser,
  eligibilitySearchRequest,
  tssFailedApplicationsListRequest,
  tssFailedApplicationRetryRequest,
  tssFailedApplicationArchiveRequest,
  staffListRequest,
  claimsListRequest,
  assignClaimsRequest,
  productConfigurationsRequest,
  updateProductConfigurationsRequest
} from "../../services/authApiService";
import loggingActions from "../logging/actions";
import appActions from "../app/actions";
import actions from "./actions";

const trimProperties = (properties) => (objectToTrim) => {
  return properties.reduce((accumulator, property) => {
    return {
      ...accumulator,
      [property]: objectToTrim[property].trim(),
    };
  }, {});
}

const trimQueryParameters = (...properties) => (payload) => {
  return {
    ...payload,
    query: {
      ...payload.query,
      ...trimProperties(properties)(payload.query),
    },
  };
};

function* fetchSearchResult(action) {
  const { payload } = action;
  const sanitizedPayload = trimQueryParameters("clientId", "email")(payload);

  const { query: { clientId } } = sanitizedPayload;
  yield call(callEndpoint, `search for ${clientId}`, fetchSearchResults, actions.setSearchResult, sanitizedPayload, actions.startFetching(), actions.endFetching());
}

function* fetchEligibilityResult(action) {
  const { payload } = action;
  const sanitizedPayload = trimQueryParameters("clientId")(payload);

  const { query: { clientId } } = sanitizedPayload;
  yield call(callEndpoint, `eligibility search for ${clientId}`, eligibilitySearchRequest, actions.setEligibilitySearchResult, sanitizedPayload, actions.startEligibilitySearch(), actions.endEligibilitySearch());
}

function* postLockUser(action) {
  const { payload } = action;
  yield call(callEndpoint, "lock", lockUser, actions.onLockUserSuccess, payload, actions.startFetching(), actions.endFetching());
}

function* postUnLockUser(action) {
  const { payload } = action;
  yield call(callEndpoint, "unlock", unLockUser, actions.onUnLockUserSuccess, payload, actions.startFetching(), actions.endFetching());
}

function* addUserStart(action) {
  const { payload } = action;
  const sanitizedPayload = trimQueryParameters("clientId")(payload);

  const { query: { clientId } } = sanitizedPayload;
  yield call(callEndpoint, `find finPOWER user ${clientId}`, addUserStartRequest, actions.onStartUserAddSuccess, sanitizedPayload, actions.startFetching(), actions.endFetching());
}

function* addUserConfirm(action) {
  const { payload } = action;
  const { query: { clientId } } = payload;
  const userAddedSuccessfully =
    yield call(callEndpoint, `created user ${clientId}`, addUserConfirmRequest, actions.onAddUserConfirmSuccess, payload, actions.startFetching(), actions.endFetching());

  if (userAddedSuccessfully) {
    yield put(appActions.setPendingRoute({ pathname: "/user", search: { keepResults: "true" } }));
  }
}

function* updateUserEmail(action) {
  const { payload } = action;
  const { query: { from, to } } = payload;
  yield call(callEndpoint, `update email "${from}" to: "${to}"`, changeUserNameRequest, actions.onEmailUpdateSuccess, payload, actions.startFetching(), actions.endFetching());
}

function* assignClaims(action) {
  const { payload } = action;
  yield call(callEndpoint, `Claims updated`, assignClaimsRequest, actions.assignClaimsSuccess, payload, actions.startFetching(), actions.endFetching());
}

function* fetchTssFailedApplicationList() {
  yield call(callEndpoint, `fetching failed tss applications`, tssFailedApplicationsListRequest, actions.setFailedApplicationsResult, {}, actions.startFailedApplicationsSearch(), actions.endFailedApplicationsSearch());
}

function* postTssFailedApplicationRetryRequest(action) {
  const { payload } = action;
  const { id } = payload.query;
  yield call(callEndpoint, `retry application id ${id}`, tssFailedApplicationRetryRequest, actions.retryFailedTssApplicationSuccess, payload, actions.startFailedApplicationsSearch(), actions.endFailedApplicationsSearch());
}

function* postTssFailedApplicationArchiveRequest(action) {
  const { payload } = action;
  const { id } = payload.query;
  yield call(callEndpoint, `archive application id ${id}`, tssFailedApplicationArchiveRequest, actions.archiveFailedTssApplicationSuccess, payload, actions.startFailedApplicationsSearch(), actions.endFailedApplicationsSearch());
}

function* getStaffList() {
  yield call(callEndpoint, `Fetching staff list`, staffListRequest, actions.setStaffUsers, {}, actions.staffSearchStart(), actions.staffSearchEnd());
}

function* getClaimsList() {
  yield call(callEndpoint, `Fetching claims list`, claimsListRequest, actions.setClaimsList, {}, actions.claimListFetchStart(), actions.claimListFetchEnd());
}

function* getProductConfigurations() {
  yield call(callEndpoint, 'Fetching Product Configurations', productConfigurationsRequest, actions.setProductConfigurations, {}, actions.startGetProductConfigurations(), actions.endGetProductConfigurations());
}

function* updateProductConfigurations(action) {
  const { payload } = action;
  yield call(callEndpointWithPayload, 'Updating Product Configurations', updateProductConfigurationsRequest, actions.updateProductConfigurationsSuccess, payload, actions.startGetProductConfigurations(), actions.endGetProductConfigurations(), actions.updateProductConfigurationsFailure());
}

function* callEndpoint(name, endpointFunction, action, payload, startingAction, endingAction) {
  let isSuccess = true;
  const { key, query } = payload;

  yield put(startingAction);
  const { status, data } = yield call(endpointFunction, query);

  if (status === 200) {
    yield put(loggingActions.addLogSuccess(name));
    yield put(action({ key, data, query }));
  } else {
    yield put(loggingActions.addLogError(isString(data) ? data : `Failed ${name}`));
    isSuccess = false;
  }

  yield put(endingAction);
  return isSuccess;
}

function* callEndpointWithPayload(name, endpointFunction, action, payload, startingAction, endingAction, failingAction) {
  let isSuccess = true;
  const { key, query } = payload;

  yield put(startingAction);

  try {
    const { status, data } = yield call(endpointFunction, payload);

    if (status === 200) {
      yield put(loggingActions.addLogSuccess(name));
      yield put(action({ key, data, query }));
    } else {
      yield put(loggingActions.addLogError(isString(data) ? data : `Failed ${name}`));
      isSuccess = false;
    }

    yield put(endingAction);
  } catch {
    yield put(failingAction);
    isSuccess = false;
  }

  return isSuccess;
}

export const __test__ = {
  fetchSearchResult,
  fetchEligibilityResult,
  postLockUser,
  postUnLockUser,
  callEndpoint,
  callEndpointWithPayload,
  addUserStart,
  addUserConfirm,
  updateUserEmail,
  fetchTssFailedApplicationList,
  postTssFailedApplicationRetryRequest,
  postTssFailedApplicationArchiveRequest,
  getStaffList,
  getClaimsList,
  assignClaims,
  getProductConfigurations,
  updateProductConfigurations,
  updateProductConfigurationsRequest
};

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SEARCH_RESULT, fetchSearchResult),
    takeEvery(actions.GET_ELIGIBILITY_SEARCH_RESULT, fetchEligibilityResult),
    takeEvery(actions.POST_USER_LOCK, postLockUser),
    takeEvery(actions.POST_USER_UNLOCK, postUnLockUser),
    takeEvery(actions.ADD_USER_START, addUserStart),
    takeEvery(actions.ADD_USER_CONFIRM, addUserConfirm),
    takeEvery(actions.UPDATE_USER_EMAIL_START, updateUserEmail),
    takeEvery(actions.GET_FAILED_APPLICATIONS_RESULT, fetchTssFailedApplicationList),
    takeEvery(actions.POST_RETRY_FAILED_APPLICATION, postTssFailedApplicationRetryRequest),
    takeEvery(actions.POST_ARCHIVE_FAILED_APPLICATION, postTssFailedApplicationArchiveRequest),
    takeEvery(actions.GET_STAFF_USERS, getStaffList),
    takeEvery(actions.GET_CLAIMS_LIST, getClaimsList),
    takeEvery(actions.START_ASSIGN_CLAIMS, assignClaims),
    takeEvery(actions.GET_PRODUCT_CONFIGURATIONS, getProductConfigurations),
    takeEvery(actions.UPDATE_PRODUCT_CONFIGURATIONS, updateProductConfigurations),
  ]);
}