import actions from "./actions";

const updateLockoutEndForMatchingUser = (payload) => (user) => {
  if (user.userName === payload.query.userName) {
    return {
      ...user,
      lockoutEnd: payload.query.optimisticLockoutEnd,
    }
  }

  return user;
};

const getSearchResultsWithUpdatedLockoutEnd = (payload, state) => ({
  ...state.searchResults,
  [payload.key]: state
    .searchResults[payload.key]
    .map(updateLockoutEndForMatchingUser(payload))
});

const updateSearchResults = (state, key, data) => ({
  ...state.searchResults,
  [key]: data,
});

const mapAddUserResultToSearchRow = ({ emailAddress, clientId, firstName, lastName }, key) => [{
  userName: emailAddress,
  clientId,
  firstName,
  hasSetPassword: "false",
  createAction: () => actions.addUserConfirm({
    key,
    query: {
      emailAddress,
      clientId,
      firstName,
      lastName
    },
  }),
}];

const getSearchResultWithoutCreateAction = (state, key) => {
  return [Object.entries(state.searchResults[key][0]).reduce((accumulator, [propName, propValue]) => {
    if (propName === "createAction") {
      return accumulator;
    }

    return {
      ...accumulator,
      [propName]: propValue,
    };
  }, {})];
};

const getSearchResultWithUpdatedUserName = (state, query) => {
  const { from, to } = query;

  return state.searchResults[state.latestKey].map((user) => {
    const { userName } = user;
    if (userName === from) {
      return {
        ...user,
        userName: to
      }
    }

    return user;
  });
};

const initState = {
  searchResults: {},
  latestKey: "",
  isFetching: false,
  showEligibility: false,
  eligibilitySearchResult: {},
  isFetchingEligibility: false,
  failedTssApplications: [],
  staffUsers: [],
  allClaims: [],
  allProductConfigurations: {},
  isFetchingClaimsList: false,
  isFetchingStaffUsers: false,
  filteredFailedTssApplications: [],
  isFetchingFailedTssApplications: false,
  isFetchingProductConfigurations: false,
  setProductConfigurationSuccess: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case actions.START_USER_FETCHING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case actions.START_ELIGIBILITY_SEARCH: {
      return {
        ...state,
        isFetchingEligibility: true,
        showEligibility: true
      }
    }
    case actions.START_FAILED_APPLICATIONS_SEARCH:
    case actions.GET_FAILED_APPLICATIONS_RESULT: {
      return {
        ...state,
        isFetchingFailedTssApplications: true
      }
    }
    case actions.START_CLAIMS_LIST_SEARCH:
    case actions.GET_CLAIMS_LIST: {
      return {
        ...state,
        isFetchingClaimsList: true
      }
    }
    case actions.GET_SEARCH_RESULT: {
      return {
        ...state,
        isFetching: true,
        isFetchingEligibility: true,
        showEligibility: false
      }
    }
    case actions.SET_SEARCH_RESULT: {
      const { key, data } = payload;
      return {
        ...state,
        latestKey: key,
        searchResults: updateSearchResults(state, key, data),
        eligibilitySearchResult: {},
        isFetchingEligibility: false,
        showEligibility: false
      };
    }
    case actions.SET_FAILED_APPLICATIONS_RESULT: {
      const { data } = payload;
      return {
        ...state,
        failedTssApplications: data,
        isFetchingFailedTssApplications: false,
      };
    }
    case actions.SET_STAFF_USERS: {
      const { data } = payload;
      return {
        ...state,
        staffUsers: data,
        isFetchingStaffUsers: false,
      };
    }
    case actions.SET_CLAIMS_LIST: {
      const { data } = payload;
      return {
        ...state,
        allClaims: data,
        isFetchingClaimsList: false,
      };
    }
    case actions.START_STAFF_SEARCH:
    case actions.GET_STAFF_USERS: {
      return {
        ...state,
        isFetchingStaffUsers: true,
      };
    }
    case actions.END_STAFF_SEARCH: {
      return {
        ...state,
        isFetchingStaffUsers: false,
      };
    }
    case actions.END_CLAIMS_LIST_SEARCH: {
      return {
        ...state,
        isFetchingClaimsList: false,
      };
    }
    case actions.END_USER_FETCHING: {
      return {
        ...state,
        isFetching: false,
        isFetchingEligibility: false,
        showEligibility: false
      };
    }
    case actions.END_ELIGIBILITY_SEARCH: {
      return {
        ...state,
        isFetchingEligibility: false,
        showEligibility: true
      };
    }
    case actions.END_FAILED_APPLICATIONS_SEARCH: {
      return {
        ...state,
        isFetchingFailedTssApplications: false
      };
    }
    case actions.POST_USER_UNLOCK_SUCCESS:
    case actions.POST_USER_LOCK_SUCCESS: {
      return {
        ...state,
        searchResults: getSearchResultsWithUpdatedLockoutEnd(payload, state)
      };
    }
    case actions.CLEAR_SEARCH_RESULT: {
      return {
        ...state,
        latestKey: "",
      }
    }
    case actions.ADD_USER_START_SUCCESS: {
      const { key, data } = payload;
      const mappedData = mapAddUserResultToSearchRow(data, key);
      return {
        ...state,
        latestKey: payload.key,
        searchResults: updateSearchResults(state, key, mappedData)
      }
    }
    case actions.ADD_USER_CONFIRM_SUCCESS: {
      const { key } = payload;
      const data = getSearchResultWithoutCreateAction(state, key);

      return {
        ...state,
        searchResults: updateSearchResults(state, key, data)
      }
    }
    case actions.UPDATE_USER_EMAIL_SUCCESS: {
      const { query } = payload;
      const data = getSearchResultWithUpdatedUserName(state, query);
      return {
        ...state,
        searchResults: updateSearchResults(state, state.latestKey, data)
      }
    }
    case actions.SET_ELIGIBILITY_SEARCH_RESULT: {
      return {
        ...state,
        eligibilitySearchResult: payload
      }
    }
    case actions.FILTER_FAILED_APPLICATIONS: {
      return {
        ...state,
        filteredFailedTssApplications: payload
      }
    }
    case actions.CLEAR_FILTER_FAILED_APPLICATIONS: {
      return {
        ...state,
        filteredFailedTssApplications: []
      }
    }
    case actions.REMOVE_FAILED_TSS_APPLICATION: {
      const newData = state.failedTssApplications.filter((data) => {
        return data.id !== payload;
      });

      return {
        ...state,
        failedTssApplications: newData
      }
    }
    case actions.GET_PRODUCT_CONFIGURATIONS: {
      return {
        ...state,
        isFetchingProductConfigurations: true
      }
    }
    case actions.SET_PRODUCT_CONFIGURATIONS: {
      return {
        ...state,
        allProductConfigurations: payload.data,
        isFetchingProductConfigurations: false
      }
    }
    case actions.UPDATE_PRODUCT_CONFIGURATIONS: {
      return {
        ...state,
        isFetchingProductConfigurations: true,
        setProductConfigurationSuccess: null
      }
    }
    case actions.UPDATE_PRODUCT_CONFIGURATIONS_SUCCESS: {
      return {
        ...state,
        isFetchingProductConfigurations: false,
        setProductConfigurationSuccess: true
      }
    }
    case actions.UPDATE_PRODUCT_CONFIGURATIONS_FAILURE: {
      return {
        ...state,
        isFetchingProductConfigurations: false,
        setProductConfigurationSuccess: false
      }
    }
    default: {
      return state;
    }
  }
}