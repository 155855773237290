import { all, takeEvery, put, delay } from "redux-saga/effects";
import uniqid from "uniqid"
import actions from "./actions";
import { createPayloadAction } from "../utility/actions";

const defaultDuration = 4000;

function* addLog(action) {
  const { payload } = action;
  const { message, level, duration = defaultDuration } = payload;
  const logId = uniqid();
  
  yield put(createPayloadAction(actions.ADD_LOG_INSERT)({level, message, logId}))
  yield delay(duration)
  yield put(createPayloadAction(actions.ADD_LOG_EXPIRE)(logId))
}

function *debugLogAction(action) {
  const { type } = action;
  const excludedActionTypes = [actions.ADD_LOG, actions.ADD_LOG_INSERT, actions.ADD_LOG_EXPIRE];
  
  if (!excludedActionTypes.includes(type)) {
    yield put(actions.addLogDebug(`Action Dispatched ${type}`, JSON.stringify(action)))
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_LOG, addLog),
    takeEvery("*", debugLogAction)
  ]);
}
