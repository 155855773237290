import React from "react";
import { Badge, IconButton } from "@material-ui/core";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import actions from "../../redux/logging/actions";

export const LoggerBadge = () => {
  const dispatch = useDispatch();
  const toggleShowNonActive = () => dispatch(actions.toggleLogWindow());
  
  return (
    <IconButton onClick={toggleShowNonActive} data-testid="button" color="inherit">
      <Badge color="secondary" overlap="rectangular">
        <ListAltIcon />
      </Badge>
    </IconButton>
  )
}