import dashboardApi from "../utils/dashboardApi";
import { API_ROUTE } from "../constants/routes";

export const fetchSearchResults =
  ({ clientId, email }) => dashboardApi
    .get(`${API_ROUTE.AUTHENTICATION_SEARCH}?clientId=${encodeURIComponent(clientId)}&email=${encodeURIComponent(email)}`)
    .catch(({ response }) => response);

export const lockUser =
  ({ userName }) => dashboardApi
    .post(`${API_ROUTE.AUTHENTICATION_LOCK}?username=${encodeURIComponent(userName)}`)
    .catch(({ response }) => response);

export const unLockUser =
  ({ userName }) => dashboardApi
    .post(`${API_ROUTE.AUTHENTICATION_UNLOCK}?username=${encodeURIComponent(userName)}`)
    .catch(({ response }) => response);

export const addUserStartRequest =
  ({ clientId }) => dashboardApi
    .get(`${API_ROUTE.AUTHENTICATION_ADD_CLIENT}/${encodeURIComponent(clientId)}`)
    .catch(({ response }) => response);

export const addUserConfirmRequest =
  (query) => dashboardApi
    .post(`${API_ROUTE.AUTHENTICATION_ADD_CLIENT}`, query)
    .catch(({ response }) => response);

export const changeUserNameRequest =
  (query) => dashboardApi
    .put(`${API_ROUTE.AUTHENTICATION_CHANGE_USERNAME}`, query)
    .catch(({ response }) => response);

export const eligibilitySearchRequest =
  ({ clientId }) => dashboardApi
    .get(`${API_ROUTE.ELIGIBILITY_SEARCH}?clientId=${clientId}`)
    .catch(({ response }) => response);

export const tssFailedApplicationsListRequest =
  () => dashboardApi
    .get(`${API_ROUTE.TSS_FAILED_APPLICATIONS_LIST}`)
    .catch(({ response }) => response);

export const tssFailedApplicationRetryRequest =
  ({ id }) => dashboardApi
    .post(`${API_ROUTE.TSS_FAILED_APPLICATION_RETRY}?id=${id}`)
    .catch(({ response }) => response);

export const tssFailedApplicationArchiveRequest =
  ({ id }) => dashboardApi
    .post(`${API_ROUTE.TSS_FAILED_APPLICATION_ARCHIVE}?id=${id}`)
    .catch(({ response }) => response);

export const staffListRequest =
  () => dashboardApi
    .get(`${API_ROUTE.STAFF_USERS}`)
    .catch(({ response }) => response);

export const claimsListRequest =
  () => dashboardApi
    .get(`${API_ROUTE.CLAIMS_LIST}`)
    .catch(({ response }) => response);

export const assignClaimsRequest =
  (query) => dashboardApi
    .post(`${API_ROUTE.ASSIGN_CLAIMS}`, query)
    .catch(({ response }) => response);

export const productConfigurationsRequest =
  () => dashboardApi
    .get(`${API_ROUTE.LOAN_PRODUCT_CONFIG}`)
    .catch(({ response }) => response);

export const updateProductConfigurationsRequest =
  (payload) => dashboardApi
    .post(`${API_ROUTE.UPDATE_LOAN_PRODUCT_CONFIG}`, payload)
    .catch(({ response }) => response);