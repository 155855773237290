import { useContext } from "../../utils/reactAdapter";
import { AuthContext } from "../../providers/authProvider";
import { createClaimType } from "../../utils/createClaimType";
import { useEffect, useState } from "react";

const accessConfig = {
  userList: {
    defaultValue: false,
    rootClaim: "staff_UserAll"
  },
  userModifyLogin: {
    defaultValue: false,
    rootClaim: "staff_UserAll"
  },
  userModifyLockout: {
    defaultValue: false,
    rootClaim: "staff_UserAll"
  },
  userAdd: {
    defaultValue: false,
    rootClaim: "staff_UserAll"
  },
  userQueryAppInsights: {
    defaultValue: false,
    rootClaim: "staff_UserAll"
  },
  jobsList: {
    defaultValue: false,
    rootClaim: "staff_JobsAll"
  },
  jobsExecute: {
    defaultValue: false,
    rootClaim: "staff_JobsAll"
  },
  jobsCreateOrModify: {
    defaultValue: false,
    rootClaim: "staff_JobsAll"
  },
  hangfireAll: {
    defaultValue: false,
    rootClaim: "staff_HangfireAll"
  },
  applicationsList: {
    defaultValue: false,
    rootClaim: "staff_ApplicationsAll"
  },
  staffList: {
    defaultValue: false,
    rootClaim: "staff_StaffList"
  },
  productConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_ProductConfigurationsAll"
  },
  productConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_ProductConfigurationsAll"
  },
  offerEngineConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_OfferEngineConfigurationsAll"
  },
  offerEngineConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_OfferEngineConfigurationsAll"
  },
  decisionCardConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_DecisionCardConfigurationsAll"
  },
  decisionCardConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_DecisionCardConfigurationsAll"
  },
  eligibilityConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_EligibilityConfigurationsAll"
  },
  eligibilityConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_EligibilityConfigurationsAll"
  },
  referralConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_ReferralConfigurationsAll"
  },
  referralConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_ReferralConfigurationsAll"
  },
  leadStatusConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_LeadStatusConfigurationsAll"
  },
  leadStatusConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_LeadStatusConfigurationsAll"
  },
  interestRateConfigurationsView: {
    defaultValue: false,
    rootClaim: "staff_InterestRateConfigurationsAll"
  },
  interestRateConfigurationsEdit: {
    defaultValue: false,
    rootClaim: "staff_InterestRateConfigurationsAll"
  },
};

const hasAccess = (profile, accessType, rootClaim) => {
  return (
    profile.hasOwnProperty(createClaimType(accessType)) ||
    profile.hasOwnProperty(rootClaim)
  );
};

const useProfile = () => {
  const { getUser } = useContext(AuthContext);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    getUser().then(({ profile: profileResult }) => {
      if (isSubscribed) {
        setProfile(profileResult);
      }
    });

    return () => (isSubscribed = false);
  }, [getUser, setProfile]);

  return profile;
};

/**
 * @returns {{userList: boolean, userModifyLogin: boolean, userModifyLockout: boolean, userAdd: boolean,
 * jobsList: boolean, jobsExecute: boolean, jobsCreateOrModify: boolean}}
 */
export const useAccessQuery = () => {
  const profile = useProfile();

  return Object.entries(accessConfig).reduce(
    (accumulator, [accessType, config]) => {
      const { defaultValue, rootClaim } = config;

      if (hasAccess(profile, accessType, rootClaim)) {
        return {
          ...accumulator,
          [accessType]: true,
        };
      }

      return {
        ...accumulator,
        [accessType]: defaultValue,
      };
    },
    {}
  );
};
