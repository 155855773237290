import { combineReducers } from "redux";
import logging from "./logging/reducer";
import pages from "./pages/reducer";
import jobDetails from "./jobDetails/reducer";
import authenticateUser from "./authenticateUser/reducer";
import app from "./app/reducer";

export const createRootReducer = () => combineReducers({
  logging,
  pages,
  jobDetails,
  authenticateUser,
  app,
});
