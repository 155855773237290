import { all, call, put, takeEvery } from "redux-saga/effects";
import { fetchJobDetail } from "../../services/fetchJobDetail";
import loggingActions from "../logging/actions";
import actions from "./actions";

function *fetchJobDetails(action) {
  const { payload: jobId } = action;
  const { status, data } = yield call(fetchJobDetail, jobId);

  if (status === 200) {
    yield put(loggingActions.addLogInfo("Fetched Job Details"));
    yield put(actions.setJobDetail({jobId, data}));
  } else {
    yield put(loggingActions.addLogError("Failed to fetched job detail for: " + jobId));
  }
}

export const __test__ = {
  fetchJobDetails,
};

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_JOB_DETAIL, fetchJobDetails),
  ]);
}