import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

const returnEntireRow = (item) => item;

const createRow = ({row, isSelected = false}) => ({isSelected, rowValue: row});

const getInitialRowSelectionState = (rows, selector) => {
  return rows.reduce((accumulator, row) => {
    const key = selector(row);
    const value = createRow({row});

    accumulator[key] = value;
    return accumulator;
  }, {});
}

const getSelectedRows = selectionState => 
  Object.values(selectionState)
    .filter(({isSelected}) => isSelected)
    .map(({rowValue}) => rowValue);

const keysHaveChanged = (selectionState, previousSelectionState) => {
  if (typeof previousSelectionState === "undefined") {
    return false; // first render, keys cannot have changed
  }
  const currentKeys = Object.keys(selectionState);
  const previousKeys = Object.keys(previousSelectionState);
  
  return currentKeys.length !== previousKeys.length || !currentKeys.every(key => previousKeys.includes(key));
};

export const useTableSelection = (rows = [], selector = returnEntireRow) => {
  const newRowSelectionState = getInitialRowSelectionState(rows, selector);
  const [selectionState, setRowSelectionState] = useState(() => newRowSelectionState);

  const previousSelectionState = usePrevious(newRowSelectionState);
  useEffect(() => {
    if (keysHaveChanged(newRowSelectionState, previousSelectionState)) {
      setRowSelectionState(newRowSelectionState);
    }
  }, [newRowSelectionState, previousSelectionState, setRowSelectionState]);
  
  const toggleRow = (row) => {
    const rowKey = selector(row);
    const { isSelected } = selectionState[rowKey];
    
    const newState = {...selectionState, [rowKey]: createRow({row, isSelected: !isSelected})};
    setRowSelectionState(newState)
  };

  const isRowSelected = (row) => !!(selectionState[selector(row)] || {}).isSelected;
  const selectedRows = getSelectedRows(selectionState);
  
  return {
    toggleRow,
    isRowSelected,
    selectedRows
  }
};