import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import AppProvider from "./AppProvider";
import App from "./App";
import LogWindow from "./components/logWindow";
import store from "./redux/store";
import boot from "./redux/boot";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
boot();

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <BrowserRouter basename={baseUrl}>
        <CssBaseline />
        <App />
        <LogWindow />
      </BrowserRouter>
    </AppProvider>
  </Provider>,
  rootElement);
