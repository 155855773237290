import React, { useState } from "react";
import clsx from "clsx";
import usePortal from "react-useportal";
import { useTransition, animated } from "react-spring";
import { useSelector } from "react-redux";
import { ButtonGroup, Button } from "@material-ui/core";
import { FairGoAlert } from "./FairGoAlert";
import useStyles from "./LogWindow.styles"
import logLevels from "../../constants/logLevels";
import { dashboardDebug } from "../../constants/environmentSettings";

const mapLogLevelToSeverity = (level) => {
  switch(level) {
    case "SUCCESS":
      return "success";
    case "ERROR":
      return "error";
    case "WARNING":
      return "warning";
    case "DEBUG":
    case "INFO":
    default:
      return "info";
  }
}

const defaultEnabledLogs = [logLevels.ERROR, logLevels.SUCCESS];

const showAsToast = (log, logFilter, showLogWindow) => {
  return (log.activeMessage || showLogWindow) && logFilter.includes(log.level);
};

const logLevelFilterOptions = dashboardDebug ? logLevels : 
  Object.entries(logLevels).reduce((accumulator, [key, value]) => {
    if (key !== logLevels.DEBUG) {
      accumulator[key] = value;
    }
    
    return accumulator;
  }, {})

const LogWindow = () => {
  const { Portal } = usePortal({
    bindTo: document && document.getElementById("log-window"),
  });
  const { logs, showLogWindow } = useSelector(state => state.logging);
  const styles = useStyles(showLogWindow);
  const [ logFilter, setLogFilter ] = useState(defaultEnabledLogs);
  const toggleLogFilterItem = (logLevel) => {
    const indexOfFilterItem = logFilter.indexOf(logLevel);
    if (indexOfFilterItem > -1) {
      setLogFilter([...logFilter.slice(0, indexOfFilterItem), ...logFilter.slice(indexOfFilterItem + 1)])
    } else {
      setLogFilter([...logFilter, logLevel])
    }
  };

  const transitions = useTransition(
    logs.filter(log => showAsToast(log, logFilter, showLogWindow)).reverse(), 
    d => d.logId, 
    {
      from: { opacity: 0, transform: "translate3D(0, -60px, 0)" },
      enter: { opacity: 1, transform: "translate3D(0, 0px, 0)" },
      leave: { opacity: 0, transform: "translate3D(0, 60px, 0)" },
    }
  );
  
  return (
    <Portal>
      <div className={clsx(styles.root, showLogWindow && styles.rootOpen)}>
        <div className={clsx(styles.alertContainer, showLogWindow && styles.alertContainerOpen)}>
          {transitions.map(({ item, props: style, key }) => (
            <animated.div
              key={key}
              style={{...style}}
            >
              <FairGoAlert className={styles.alert} severity={mapLogLevelToSeverity(item.level)}>{item.message}</FairGoAlert>
            </animated.div>
          ))}
        </div>
        <ButtonGroup color="primary" className={clsx(styles.logButtonGroup, showLogWindow && styles.showLogButtonGroup)} aria-label="log level selection">
          {Object.values(logLevelFilterOptions).map((logLevel, index) => (
            <Button
              variant={logFilter.includes(logLevel) ? "contained" : "outlined"} 
              onClick={() => toggleLogFilterItem(logLevel)} 
              key={index}
            >
              {logLevel}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </Portal>
  )
};

export default LogWindow;