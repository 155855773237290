import actions from "./actions";

const initState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case actions.SET_PAGE_CONTENTS:
      const existingResource = state[payload.resourceName] || {};
      const existingResourcePage = existingResource[payload.key] || {};
      return {
        ...state,
        [payload.resourceName]: {
          ...existingResource,
          [payload.key]: {
            ...existingResourcePage,
            contents: payload.pageContents
          }
        }
      }
    default:
      return state;
  }
}