import { createEmptyAction, createPayloadAction } from "../utility/actions";
import logLevels from "../../constants/logLevels";
import { titleCase } from "../../utils/stringUtils";

const ADD_LOG = "ADD_LOG";
const ADD_LOG_INSERT = "ADD_LOG_INSERT";
const ADD_LOG_EXPIRE = "ADD_LOG_EXPIRE";
const TOGGLE_LOG_WINDOW = "TOGGLE_LOG_WINDOW";
const addLog = (message, _details = "", options = {}) => createPayloadAction(ADD_LOG)({...options, message});

// creates a function for each logLevel, i.e. addLogDebug, addLogSuccess
const addLogActions = Object.entries(logLevels).reduce((accumulator, [key, value]) => {
  const addLogFunc = (message, details = "", options = {}) => addLog(message, details, {...options, level: value});
  accumulator[`addLog${titleCase(key)}`] = addLogFunc;
  return accumulator;
}, {});

const actions = {
  ADD_LOG,
  ADD_LOG_INSERT,
  ADD_LOG_EXPIRE,
  addLog,
  ...addLogActions,
  TOGGLE_LOG_WINDOW,
  toggleLogWindow: createEmptyAction(TOGGLE_LOG_WINDOW),
};

export default actions;
