import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SET_PENDING_ROUTE = "SET_PENDING_ROUTE";
const CLEAR_PENDING_ROUTE = "CLEAR_PENDING_ROUTE";

const actions = {
  SET_PENDING_ROUTE,
  CLEAR_PENDING_ROUTE,
  setPendingRoute: createPayloadAction(SET_PENDING_ROUTE),
  clearPendingRoute: createEmptyAction(CLEAR_PENDING_ROUTE),
};

export default actions;