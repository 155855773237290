import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types"
import { ChevronLeft as ChevronLeftIcon } from "@material-ui/icons";
import { IconButton, Drawer } from "@material-ui/core";
import useStyles from "./DashboardDrawer.styles";
import { UserListItems, JobListItems, ApplicationsListItems, StaffListItems, LogoutItem, ConfigurationListItems, PartnersListItems } from "./listItems";

export const DashboardDrawer = (props) => {
  const { open, handleDrawerClose } = props;
  const classes = useStyles();

  return (
    <nav className={classes.nav} aria-label="Dashboard Navigation">
      <Drawer
        variant="persistent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        style={{ display: open ? "flex" : "none" }}
        open={open}
      >
        <DashboardDrawerItems
          handleDrawerClose={handleDrawerClose}
          className={classes.toolbarIcon}
        />
      </Drawer>
    </nav>
  );
}

DashboardDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired
}

const DashboardDrawerItems = (props) => {
  const { className, handleDrawerClose } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: "100vh" }}>
      <div className={className}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <JobListItems />
      <UserListItems />
      <ApplicationsListItems />
      <StaffListItems />
      <ConfigurationListItems />
      <PartnersListItems />
      <LogoutItem />
    </div>
  );
}