const scheduledJob = "/ScheduledJob";
const scheduledJobFinpowerScript = scheduledJob + "/FinpowerScript";
const job = "/job";
const jobDetails = job + "/Details";
const authentication = "/authentication";
const tssFailedApplications = "/tssFailedApplications";
const loanProductConfig = "/loanProductConfig";
const authenticationSearch = authentication + "/search";
const authenticationLock = authentication + "/lock";
const authenticationUnLock = authentication + "/unlock";
const authenticationAddClient = authentication + "/addClient";
const authenticationChangeUserName = authentication + "/changeUsername";
const applicationInsights = "/ApplicationInsights";
const clientEligibilityLogs = applicationInsights + "/query/clientEligibilityLogs";
const tssFailedApplicationsList = tssFailedApplications;
const tssFailedApplicationsRetry = tssFailedApplications + "/retry";
const tssFailedApplicationsArchive = tssFailedApplications + "/remove";
const staffUsers = authentication + "/getStaffUsers";
const claimsList = authentication + "/allClaims";
const assignStaffClaims = authentication + "/assignStaffClaims";

export const API_ROUTE = {
  BASE_URL: "/api",
  SCHEDULED_JOB_FINPOWER_SCRIPT_JOBS: scheduledJobFinpowerScript + "/Jobs",
  SCHEDULED_JOB_FINPOWER_SCRIPT_TRIGGER: scheduledJobFinpowerScript + "/Trigger",
  JOB: job,
  JOB_DETAILS: jobDetails,
  AUTHENTICATION_SEARCH: authenticationSearch,
  AUTHENTICATION_LOCK: authenticationLock,
  AUTHENTICATION_UNLOCK: authenticationUnLock,
  AUTHENTICATION_ADD_CLIENT: authenticationAddClient,
  AUTHENTICATION_CHANGE_USERNAME: authenticationChangeUserName,
  ELIGIBILITY_SEARCH: clientEligibilityLogs,
  TSS_FAILED_APPLICATIONS_LIST: tssFailedApplicationsList,
  TSS_FAILED_APPLICATION_RETRY: tssFailedApplicationsRetry,
  TSS_FAILED_APPLICATION_ARCHIVE: tssFailedApplicationsArchive,
  STAFF_USERS: staffUsers,
  CLAIMS_LIST: claimsList,
  ASSIGN_CLAIMS: assignStaffClaims,
  LOAN_PRODUCT_CONFIG: loanProductConfig,
  UPDATE_LOAN_PRODUCT_CONFIG: loanProductConfig + "/update"
};

export const PRIVATE_ROUTE = {
  DASHBOARD: "/",
  JOBS_FAILED: "/jobs/Failed",
  JOBS_PROCESSING: "/jobs/Processing",
  JOBS_SUCCEEDED: "/jobs/Succeeded",
  JOBS_RECURRING: "/jobs/Recurring",
  JOBS_HANGFIRE: "/hangfire",
  JOB_DETAIL: "/job/:jobId",
  USER_DETAIL: "/user",
  LOGOUT: "/logout",
  USER_ADD: "/user/add",
  APPLICATIONS_FAILED: "/applications/Failed",
  STAFF_CLAIMS: "/staff/claims",
  CONFIGURATION_PRODUCTS: "/configuration/products",
  CONFIGURATION_OFFER_ENGINE: "/configuration/offerEngine",
  CONFIGURATION_DECISION_CARD: "/configuration/decisionCard",
  CONFIGURATION_INTEREST_RATES: "/configuration/interestRates",
  PARTNERS_ELIGIBILITY: "/partners/eligibility",
  PARTNERS_REFERRALS: "/partners/referrals",
  PARTNERS_LEAD_STATUS: "/partners/leadStatus"
};
