import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../redux/app/actions";

export const useAppRouter = () => {
  const location = useLocation();
  const history = useHistory();
  const { pendingRoute } = useSelector(({app}) => app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pendingRoute !== null && pendingRoute !== location.pathname) {
      dispatch(appActions.clearPendingRoute());
      history.push(pendingRoute);
    }
  }, [dispatch, history, location.pathname, pendingRoute]);
};