import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "./containers/dashboard";
import actions from "./redux/logging/actions";
import { AuthProvider } from "./providers/authProvider";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LoggedOut } from "./components/auth/loggedOut";
import { Logout } from "./components/auth/logout";
import { LogoutCallback } from "./components/auth/logoutCallback";
import { SilentRenew } from "./components/auth/silentRenew";
import { Callback } from "./components/auth/callback";
import { AxiosAuthInterceptor } from "./components/axiosAuthInterceptor";
import { PrivateRoute } from "./components/routes";

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      hasError: false
    }
  }
  static displayName = App.name;

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  
  componentDidCatch(error, errorInfo) {
    this.props.addLogDebug(`${error.name}: ${error.message}`, JSON.stringify({error, errorInfo}), {duration: 99999999});
  }
  
  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. Please reload</h1>;
    }
    return (
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route exact={true} path="/signin-oidc" component={Callback} />
            <Route exact={true} path="/logged-out" component={LoggedOut} />
            <Route exact={true} path="/logout" component={Logout} />
            <Route exact={true} path="/logout/callback" component={LogoutCallback} />
            <Route exact={true} path="/silent-renew" component={SilentRenew} />
            <PrivateRoute path="/" component={Dashboard} />
        </Switch>
        </BrowserRouter>
        <AxiosAuthInterceptor />
      </AuthProvider>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = { addLogDebug: actions.addLogDebug }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)