import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  logoutButton: {
    margin: "0 auto"
  },
  logoutRoot: {
    marginTop: "auto"
  },
  hangfireText: {
    padding: "0",
    color: "inherit"
  },
  listSubHeader: {
    borderBottom: '1.5px solid #d3d3d3',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#00673F'
  }
}));

export default useStyles;