import { all } from "redux-saga/effects";
import loggingSagas from "./logging/sagas";
import pagesSagas from "./pages/sagas";
import jobDetailsSagas from "./jobDetails/sagas";
import authenticateUserSagas from "./authenticateUser/sagas";

export default function* rootSaga(getState) {
  yield all([
    loggingSagas(getState), 
    pagesSagas(getState),
    jobDetailsSagas(getState),
    authenticateUserSagas(getState),
  ]);
}
