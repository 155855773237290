import { pageKeyGenerator } from "../../utils/pageKeyGenerator";
import { createPayloadAction } from "../utility/actions";

const FETCH_PAGE = "FETCH_PAGE";
const SET_PAGE_CONTENTS = "SET_PAGE_CONTENTS";
const TRIGGER_JOB_LIST = "TRIGGER_JOB_LIST";

const fetchPage = (({pageDefinition, sort, count, from} = {}) => {
  return {
    type: FETCH_PAGE,
    payload: {
      pageKey: pageKeyGenerator({sort, count, from}),
      pageDefinition,
    }
  };
});

const actions = {
  FETCH_PAGE,
  SET_PAGE_CONTENTS,
  TRIGGER_JOB_LIST,
  fetchPage,
  setPageContents: createPayloadAction(SET_PAGE_CONTENTS),
  triggerJobList: createPayloadAction(TRIGGER_JOB_LIST),
}

export default actions;