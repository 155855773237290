import { getNow } from "../constants/now";
import { formatDistance, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";

const defaultOptions = { updateInterval: 15000 };

const getReadableTimeUntilNow = dateString => {
  if (dateString === null || typeof(dateString) === "undefined") {
    return "";
  }

  const now = getNow();
  const date = parseISO(dateString);
  const agoText = date < now ? " ago" : "";
  return formatDistance(date, now) + agoText;
};

const updateStateOnFurtherRenders = (previousDateString, dateString, setReadableTimeUntilNow) => {
  if (typeof previousDateString !== "undefined" && previousDateString !== dateString) {
    setReadableTimeUntilNow(getReadableTimeUntilNow(dateString));
  }
};

export const useReadableTimeUntilNow = (dateString, options = defaultOptions) => {
  const [ readableTimeUntilNow, setReadableTimeUntilNow ] = useState(() => getReadableTimeUntilNow(dateString));
  const previousDateString = usePrevious(dateString);

  useEffect(() => {
    updateStateOnFurtherRenders(previousDateString, dateString, setReadableTimeUntilNow);
  }, [dateString, setReadableTimeUntilNow, previousDateString]);

  useEffect(() => {
    const interval = setInterval(() => {
      setReadableTimeUntilNow(getReadableTimeUntilNow(dateString));
    }, options.updateInterval);
    return () => clearInterval(interval);
  }, [dateString, setReadableTimeUntilNow, options.updateInterval]);

  return readableTimeUntilNow;
};