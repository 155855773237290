import actions from "./actions";

const initState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case actions.SET_JOB_DETAIL: {
      const { jobId, data } = payload;
      return {
        ...state,
        [jobId]: data,
      };
    }
    default: {
      return state;
    }
  }
}