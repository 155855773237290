import React from "react";
import { useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import {
  Add as AddIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Dashboard as DashboardIcon,
  ErrorOutline as ErrorOutlineIcon,
  ExitToApp as LogoutIcon,
  PersonOutline as PersonOutlineIcon,
  HowToReg as HowToRegIcon,
  Ballot as BallotIcon,
  Cached as CachedIcon,
  DesktopWindows as Desktop,
  AccountTree as AccountTreeIcon,
  CompareArrows as CompareArrowsIcon,
  Contacts as ContactsIcon,
  MultilineChart as MultilineChartIcon,
  VerifiedUser,
  LiveHelp
} from "@material-ui/icons";
import { PRIVATE_ROUTE } from "../../constants/routes";
import { MaterialLink } from "../../components/utility";
import { useAccessQuery } from "../../hooks/auth";
import { AuthDisabledTooltip } from "../../components/auth/authDisabledTooltip";

import useStyles from "./listItems.style";

export const ListItemNavigator = ({
  title,
  renderIcon,
  to,
  className,
  disabled = false
}) => {
  const location = useLocation();

  return (
    <AuthDisabledTooltip disabled={disabled}>
      <ListItem
        disabled={disabled}
        className={className}
        selected={to === location.pathname}
        button
        to={to}
        component={MaterialLink}
      >
        <ListItemIcon>{renderIcon()}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </AuthDisabledTooltip>
  );
};

function ListItemLink(props) {
  return <ListItem component="a" {...props} />;
}

export const ListItemLinkNavigator = ({
  title,
  renderIcon,
  to,
  disabled = false
}) => {
  const classes = useStyles();
  return (
    <AuthDisabledTooltip disabled={disabled}>
      <ListItem button disabled={disabled}>
        <ListItemIcon>{renderIcon()}</ListItemIcon>
        <ListItemLink
          href={to}
          classes={{
            root: classes.hangfireText,
          }}
        >
          <ListItemText primary={title} />
        </ListItemLink>
      </ListItem>
    </AuthDisabledTooltip>
  );
};

export const JobListItems = () => {
  const { jobsList } = useAccessQuery();
  const { hangfireAll } = useAccessQuery();
  const classes = useStyles();

  return (
    <List
      style={{
        padding: 0,
      }}
    >
      <ListSubheader className={classes.listSubHeader}>Jobs</ListSubheader>
      <ListItemNavigator
        title="Recurring"
        to={PRIVATE_ROUTE.JOBS_RECURRING}
        renderIcon={() => <DashboardIcon />}
        disabled={!jobsList}
      />
      <ListItemNavigator
        title="Processing"
        to={PRIVATE_ROUTE.JOBS_PROCESSING}
        renderIcon={() => <CachedIcon />}
        disabled={!jobsList}
      />
      <ListItemNavigator
        title="Succeeded"
        to={PRIVATE_ROUTE.JOBS_SUCCEEDED}
        renderIcon={() => <CheckCircleOutlineIcon />}
        disabled={!jobsList}
      />
      <ListItemNavigator
        title="Failed"
        to={PRIVATE_ROUTE.JOBS_FAILED}
        renderIcon={() => <ErrorOutlineIcon />}
        disabled={!jobsList}
      />
      <ListItemLinkNavigator
        title="Hangfire"
        to={PRIVATE_ROUTE.JOBS_HANGFIRE}
        renderIcon={() => <Desktop />}
        disabled={!hangfireAll}
      />
    </List>
  );
};

export const StaffListItems = () => {
  const { staffList } = useAccessQuery();
  const classes = useStyles();

  return (
    <List>
      <ListSubheader className={classes.listSubHeader}>
        Manage Claims
      </ListSubheader>
      <ListItemNavigator
        title="Staff"
        to={PRIVATE_ROUTE.STAFF_CLAIMS}
        renderIcon={() => <HowToRegIcon />}
        disabled={!staffList}
      />
    </List>
  );
};

export const UserListItems = () => {
  const { userList, userAdd } = useAccessQuery();
  const classes = useStyles();

  return (
    <List
      style={{
        paddingTop: 0,
      }}
    >
      <ListSubheader className={classes.listSubHeader}>Users</ListSubheader>
      <ListItemNavigator
        title="Search"
        to={PRIVATE_ROUTE.USER_DETAIL}
        renderIcon={() => <PersonOutlineIcon />}
        disabled={!userList}
      />
      <ListItemNavigator
        title="Add"
        to={PRIVATE_ROUTE.USER_ADD}
        renderIcon={() => <AddIcon />}
        disabled={!userAdd}
      />
    </List>
  );
};

export const ApplicationsListItems = () => {
  const { applicationsList } = useAccessQuery();
  const classes = useStyles();

  return (
    <List
      style={{
        paddingTop: 0,
      }}
    >
      <ListSubheader className={classes.listSubHeader}>
        Applications
      </ListSubheader>
      <ListItemNavigator
        title="Failed"
        to={PRIVATE_ROUTE.APPLICATIONS_FAILED}
        renderIcon={() => <ErrorOutlineIcon />}
        disabled={!applicationsList}
      />
    </List>
  );
};

export const ConfigurationListItems = () => {
  const {
    productConfigurationsView,
    productConfigurationsEdit,
    offerEngineConfigurationsView,
    offerEngineConfigurationsEdit,
    decisionCardConfigurationsView,
    decisionCardConfigurationsEdit,
    interestRateConfigurationsView,
    interestRateConfigurationsEdit,
  } = useAccessQuery();

  const classes = useStyles();

  return (
    <List
      style={{
        paddingTop: 0,
      }}
    >
      <ListSubheader className={classes.listSubHeader}>
        Manage Configuration
      </ListSubheader>
      <ListItemNavigator
        title="Products"
        to={PRIVATE_ROUTE.CONFIGURATION_PRODUCTS}
        renderIcon={() => <BallotIcon />}
        disabled={!productConfigurationsView && !productConfigurationsEdit}
      />
      <ListItemNavigator
        title="Offer Engine"
        to={PRIVATE_ROUTE.CONFIGURATION_OFFER_ENGINE}
        renderIcon={() => <AccountTreeIcon />}
        disabled={
          !offerEngineConfigurationsView && !offerEngineConfigurationsEdit
        }
      />
      <ListItemNavigator
        title="Decision Card"
        to={PRIVATE_ROUTE.CONFIGURATION_DECISION_CARD}
        renderIcon={() => <CompareArrowsIcon />}
        disabled={
          !decisionCardConfigurationsView && !decisionCardConfigurationsEdit
        }
      />
      <ListItemNavigator
        title="Interest Rates"
        to={PRIVATE_ROUTE.CONFIGURATION_INTEREST_RATES}
        renderIcon={() => <MultilineChartIcon />}
        disabled={
          !interestRateConfigurationsView && !interestRateConfigurationsEdit
        }
      />
    </List>
  );
};

export const PartnersListItems = () => {
  const
    {
      eligibilityConfigurationsView,
      eligibilityConfigurationsEdit,
      referralConfigurationsView,
      referralConfigurationsEdit,
      leadStatusConfigurationsView,
      leadStatusConfigurationsEdit
    } = useAccessQuery();

  const classes = useStyles();

  return (
    <List
      style={{
        paddingTop: 0
      }}>
      <ListSubheader className={classes.listSubHeader}>
        Partners
      </ListSubheader>
      <ListItemNavigator
        title="Eligibility"
        to={PRIVATE_ROUTE.PARTNERS_ELIGIBILITY}
        renderIcon={() => <VerifiedUser />}
        disabled={!eligibilityConfigurationsView && !eligibilityConfigurationsEdit}
      />
      <ListItemNavigator
        title="Referrals"
        to={PRIVATE_ROUTE.PARTNERS_REFERRALS}
        renderIcon={() => <ContactsIcon />}
        disabled={!referralConfigurationsView && !referralConfigurationsEdit}
      />
      <ListItemNavigator
        title="Lead Status"
        to={PRIVATE_ROUTE.PARTNERS_LEAD_STATUS}
        renderIcon={() => <LiveHelp />}
        disabled={!leadStatusConfigurationsView && !leadStatusConfigurationsEdit}
      />
    </List>);
}

export const LogoutItem = () => {
  const classes = useStyles();
  return (
    <List className={classes.logoutRoot}>
      <ListItemNavigator
        title="LOGOUT"
        to={PRIVATE_ROUTE.LOGOUT}
        renderIcon={() => <LogoutIcon />}
        className={classes.logoutButton}
      />
    </List>
  );
};
